import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Text } from 'components/Typography'

export type AnimatedMemoryActionVariant = 'dark' | 'light'

export const SWrapper = styled.div<{ noLabel: boolean; variant: AnimatedMemoryActionVariant }>`
  cursor: pointer;
  display: flex;
  height: 2.5rem;

  align-items: center;
  justify-content: center;
  border-radius: 1.25rem;
  padding: 0 0.75rem;

  transition: box-shadow 0.2s;
  box-shadow: var(--card-shadow-light);

  &:hover {
    box-shadow: var(--card-shadow-light-hover);
  }

  &:active {
    box-shadow: var(--card-shadow-light-active);
  }

  ${({ noLabel }) =>
    noLabel &&
    css`
      padding: 0;
      min-width: 2.5rem;

      ${SText} {
        display: none;
      }
    `}

  ${({ variant }) =>
    css`
      background: ${variant === 'dark' ? 'black' : 'white'};

      ${SText} {
        color: ${variant === 'dark' ? 'white' : 'black'};
      }
    `}
`

export const SText = styled(Text)`
  margin-left: 0.75rem;
  font-weight: bold;
`
