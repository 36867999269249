import styled from '@emotion/styled'
import { pxToRem } from 'utils/styled'

export const SMemoriesCarousel = styled.div<{ hideNavigation?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & .navigator {
    ${({ hideNavigation }) => hideNavigation && 'visibility: hidden;'}
  }
`

export const SMemoryContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const SIconButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: var(--card-shadow);
  width: ${pxToRem(36)}rem;
  height: ${pxToRem(36)}rem;
  color: var(--c-blue500);
  aspect-ratio: 1;
  background-color: white;
`

export const SLeftButton = styled(SIconButton)`
  margin-right: ${pxToRem(12)}rem;
  transform: rotateY(180deg);
`

export const SRightButton = styled(SIconButton)`
  margin-left: ${pxToRem(12)}rem;
`
