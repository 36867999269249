import { Asset, Maybe } from 'api/contentful/contentful.graphql.types'
import { Dispatch, ReactElement, ReactNode, SetStateAction, useState } from 'react'
import {
  SLeftButton,
  SMemoriesCarousel,
  SMemoryContentWrapper,
  SRightButton,
} from './MemoryCarousel.styled'
import { AspectRatio } from 'components/AspectRatio'
import { SActionsWrapper, SVideoWithPreview } from './VideoCarousel.styled'
import { SDot, SDotsContainer } from 'components/dots/DotsContainer'
import { Spacer } from 'components/spacer/Spacer'
import { ToggleAudioButton } from 'components/toggleAudioButton/ToggleAudioButton'
import { AutoPlayer } from 'components/videoPlayer/autoPlayer/AutoPlayer'
import { Icon } from 'components/icon'
import { GenericFunction } from 'utils/types'
import { css } from '@emotion/react'
import { VideoPlayerPlaceholder } from 'components/videoPlayer/videoPlayerPlaceholder/VideoPlayerPlaceholder'

type TVideoCarousel = {
  photo?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'fileName' | 'url'>>
  video?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'fileName' | 'url'>>
  songName?: Maybe<string>
}

type TVideoWithPreview = {
  photoSrc?: string
  videoSrc: string
}

export function VideoWithPreview(props: {
  data: TVideoWithPreview
  muted?: boolean
  children?: ReactElement
  onVideoEnded?: GenericFunction
  customPlaceholder?: ReactNode
}) {
  return (
    <SVideoWithPreview>
      <AspectRatio ratio={1}>
        <AutoPlayer
          src={props.data.videoSrc}
          muted={props.muted}
          onEnded={props.onVideoEnded}
          customPlaceholder={props.customPlaceholder}
        >
          {props.children}
        </AutoPlayer>
      </AspectRatio>
      {props.data.photoSrc && <img src={props.data.photoSrc} />}
    </SVideoWithPreview>
  )
}

export function VideoCarousel(props: {
  items?: (null | TVideoCarousel)[]
  disableAudio?: boolean
  extraActions?: ReactElement
  hideActionText?: boolean
  hideNavigation?: boolean
  forcedIndex?: number
  setForcedIndex?: Dispatch<SetStateAction<number>>
  onVideoEnded?: GenericFunction
  className?: string
  hideDots?: boolean
  iconSize?: string
  iconPlacement?: 'left' | 'right'
}) {
  const { items } = props

  const [index, setIndex] = useState(0)
  const length = items?.length || 0

  const [muted, setMuted] = useState(true)

  const iterate = (direction: 'left' | 'right') => {
    if (!props.items) return

    setMuted(true)

    if (direction === 'left') {
      index <= 0 ? setIndex(length - 1) : setIndex(index - 1)
    } else {
      index >= length - 1 ? setIndex(0) : setIndex(index + 1)
    }
  }

  const currentIndex = props.forcedIndex ?? index
  const memory = items?.[currentIndex]

  const onChange = (index: number) => {
    setIndex(index)
    props.setForcedIndex?.(index)
  }

  return (
    <SMemoriesCarousel hideNavigation={!items || length <= 1} className={props?.className}>
      <SMemoryContentWrapper>
        {!props.hideNavigation && (
          <SLeftButton className="navigator" onClick={() => iterate('left')}>
            <Icon name="ChevronRight" size="1rem" />
          </SLeftButton>
        )}
        <VideoWithPreview
          data={{
            photoSrc: memory?.photo?.url as string,
            videoSrc: memory?.video?.url as string,
          }}
          muted={muted}
          onVideoEnded={props.onVideoEnded}
          customPlaceholder={<VideoPlayerPlaceholder ratio={1} css={{ borderRadius: '1rem' }} />}
        >
          <SActionsWrapper
            css={css`
              ${props.iconPlacement === 'right'
                ? `
              top: 0.5rem;
              right: 0.5rem;
              left: unset;
              min-width: 1.5rem;
              height: 1.5rem;
              & > div {
                width: 1.5rem;
                min-width: 1.5rem;
              }`
                : null}
            `}
          >
            {!props.disableAudio && (
              <ToggleAudioButton
                label={(!props.hideActionText && memory?.songName) || ''}
                onClick={() => setMuted((m) => !m)}
                muted={muted}
                iconSize={props.iconSize}
              />
            )}
            {props.extraActions}
          </SActionsWrapper>
        </VideoWithPreview>
        {!props.hideNavigation && (
          <SRightButton className="navigator" onClick={() => iterate('right')}>
            <Icon name="ChevronRight" size="1rem" />
          </SRightButton>
        )}
      </SMemoryContentWrapper>

      {!props.hideDots && (
        <>
          <Spacer size={1} />
          <SDotsContainer className="navigator">
            {items?.map((_, i) => (
              <SDot
                key={i}
                isActive={i === currentIndex}
                onClick={() => onChange(i)}
                disabled={props.hideDots}
              />
            ))}
          </SDotsContainer>
        </>
      )}
    </SMemoriesCarousel>
  )
}
