import useSWRInfinite from 'swr/infinite'
import useSWR from 'swr'
import { encodeQuery, FetchCall, FetchResponse, QueryParams } from 'utils/fetcher'
import { ApiType } from 'utils/types'
import { apiAuthFetcher, apiFetcher } from 'api/goodtrust/api'
import { encodePageQuery } from 'utils/pagination'
import FormData from 'isomorphic-form-data'
import { SWRConfiguration } from 'swr'
import { useAuthAwareFetcher } from 'utils/hooks'
import { useFutureMessageStorage } from 'components/futureMessage/FutureMessageUtils'
import { useRef } from 'react'

export function useUserFutureMessages(args?: QueryParams) {
  return useSWRInfinite<FetchResponse<ApiType['PaginatedResponseFutureMessageResponse']>>(
    (_, prev) => encodePageQuery('/api/v1/user/future-message', prev, args),
    apiAuthFetcher
  )
}

export function useUserFutureMessage(
  uuid: string | null | undefined,
  config?: SWRConfiguration<FetchResponse<ApiType['FutureMessageResponse']>, Error>
) {
  const random = useRef(Date.now())
  const fetcher = useAuthAwareFetcher()
  const { userUuid } = useFutureMessageStorage()
  return useSWR<FetchResponse<ApiType['FutureMessageResponse']>>(
    [
      encodeQuery(`/api/v1/user/future-message/${uuid}`, { source: userUuid }),
      fetcher,
      userUuid,
      random,
    ],
    fetcher,
    config
  )
}

export function getUserFutureMessage(uuid: string, fetcher: FetchCall, userUuid?: string | null) {
  return fetcher?.(encodeQuery(`/api/v1/user/future-message/${uuid}`, { source: userUuid }), {
    method: 'GET',
  })
}

export function postUserFutureMessage(
  body: ApiType['CreateFutureMessageCommand'],
  fetcher?: FetchCall | null
) {
  if (!fetcher) fetcher = apiAuthFetcher
  return fetcher<ApiType['FutureMessageResponse']>('/api/v1/user/future-message', {
    method: 'POST',
    body,
  })
}

export function putUserFutureMessage(
  uuid: string,
  body: ApiType['UpdateFutureMessageCommand'],
  fetcher?: FetchCall | null,
  userUuid?: string | null
) {
  if (!fetcher) fetcher = apiAuthFetcher
  return fetcher<ApiType['FutureMessageResponse']>(
    encodeQuery(`/api/v1/user/future-message/${uuid}`, { source: userUuid }),
    {
      method: 'PUT',
      body,
    }
  )
}

export function putUserFutureMessageFinish(uuid: string, fetcher?: FetchCall) {
  if (!fetcher) fetcher = apiAuthFetcher
  return fetcher<ApiType['FutureMessageResponse']>(`/api/v1/user/future-message/${uuid}/finish`, {
    method: 'PUT',
  })
}

export function postUserFutureMessageBind(uuid: string) {
  return apiAuthFetcher<ApiType['FutureMessageResponse']>(
    `/api/v1/user/future-message/bind/${uuid}`,
    {
      method: 'POST',
    }
  )
}

export function postUserFutureMessageSave(uuid: string) {
  return apiAuthFetcher<ApiType['FutureMessageResponse']>(
    `/api/v1/user/future-message/save/${uuid}`,
    {
      method: 'POST',
    }
  )
}

export function deleteUserFutureMessage(uuid: string | undefined) {
  return apiAuthFetcher(`/api/v1/user/future-message/${uuid}`, {
    method: 'DELETE',
  })
}

export function postUserFutureMessageFileBegin(
  uuid: string,
  body: ApiType['UploadRequest'],
  fetcher?: FetchCall | null,
  userUuid?: string | null
) {
  if (!fetcher) fetcher = apiAuthFetcher
  return fetcher<ApiType['UploadResponse']>(
    encodeQuery(`/api/v1/user/future-message/${uuid}/file/begin`, {
      source: userUuid,
    }),
    {
      method: 'POST',
      body,
    }
  )
}

export function postUserFutureMessageFileChunk(
  uuid: string,
  meta: ApiType['UploadChunkRequest'],
  file: Blob,
  fetcher?: FetchCall | null
) {
  if (!fetcher) fetcher = apiAuthFetcher
  const body = new FormData()
  body.append('request', new Blob([JSON.stringify(meta)], { type: 'application/json' }))
  body.append('file', file)

  return fetcher<ApiType['UploadPartResponse']>(`/api/v1/user/future-message/${uuid}/file/chunk`, {
    method: 'POST',
    body,
  })
}

export function putUserFutureMessageFileAbort(
  uuid: string,
  body: ApiType['UploadAbortRequest'],
  fetcher?: FetchCall | null
) {
  if (!fetcher) fetcher = apiAuthFetcher
  return fetcher<void>(`/api/v1/user/future-message/${uuid}/file/abort`, {
    method: 'PUT',
    body,
  })
}

export function putUserFutureMessageFileFinalize(
  uuid: string,
  body: ApiType['UploadFinalizeRequest'],
  fetcher?: FetchCall | null
) {
  if (!fetcher) fetcher = apiAuthFetcher
  return fetcher<ApiType['FileResponse']>(`/api/v1/user/future-message/${uuid}/file/finalize`, {
    method: 'PUT',
    body,
  })
}

export function postShareFutureMessageEmail(
  uuid: string,
  body: ApiType['FutureMessageShareEmailRequest']
) {
  const fetcher = body.sender_email ? apiFetcher : apiAuthFetcher
  return fetcher(`/api/v1/user/future-message/${uuid}/share/email`, {
    method: 'POST',
    body,
  })
}

export function useUserFutureMessageCount(noCache?: boolean) {
  const random = useRef(Date.now())
  if (noCache) {
    return useSWR<FetchResponse<ApiType['FutureMessageCountResponse']>>(
      ['/api/v1/user/future-message/count', random],
      apiAuthFetcher
    )
  } else {
    return useSWR<FetchResponse<ApiType['FutureMessageCountResponse']>>(
      '/api/v1/user/future-message/count',
      apiAuthFetcher
    )
  }
}
