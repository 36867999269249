import { AnimatedMemoryActionVariant, SText, SWrapper } from './AnimatedMemoryAction.styled'
import { ReactElement } from 'react'

interface Props {
  className?: string
  label?: string
  icon?: ReactElement
  onClick?: () => void
  variant?: AnimatedMemoryActionVariant
}

export function AnimatedMemoryAction({ variant = 'light', ...props }: Props) {
  return (
    <SWrapper
      variant={variant}
      className={props.className}
      onClick={props.onClick}
      noLabel={!props.label}
    >
      {props.icon}
      <SText>{props.label}</SText>
    </SWrapper>
  )
}
