import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const SDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const SDot = styled.div<{ isActive?: boolean; disabled?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 6px;
  background-color: ${({ isActive }) => (isActive ? 'var(--c-blue500)' : 'var(--c-blue100)')};
  :hover {
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default !important;
    `}
`
