import styled from '@emotion/styled'

export const SHorizontalScroll = styled.div<{ leftOffset?: number; leftOffsetStr?: string }>`
  position: relative;
  width: calc(
    100% +
      ${({ leftOffset, leftOffsetStr }) =>
        leftOffsetStr ? leftOffsetStr : leftOffset ? leftOffset + 'px' : '0px'}
  );
  margin: 0 0 0 -${({ leftOffset, leftOffsetStr }) => (leftOffsetStr ? leftOffsetStr : leftOffset ? leftOffset + 'px' : '0px')};
`

export const SHorizontalScrollContent = styled.div`
  overflow-x: scroll;
  display: grid;
  grid-gap: 14px;
  padding: 20px 30px 30px;
  grid-auto-flow: column;
  scrollbar-width: none;
  justify-content: start;
  overscroll-behavior-x: contain;

  &::after {
    content: '';
    width: 30px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & > div:last-child {
    margin-right: 20px;
  }
`

export const SHorizontalScrollOverlayLeft = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 100%;
  background: linear-gradient(90deg, white 42%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
`

export const SHorizontalScrollOverlayRight = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 100%;
  background: linear-gradient(270deg, white 42%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
`
