import { SAnimatedMemoryAction } from './ToggleAudioButton.styled'
import { Icon } from 'components/icon'

export function ToggleAudioButton(props: {
  className?: string
  label?: string
  muted?: boolean
  onClick?: () => void
  iconSize?: string
}) {
  return (
    <SAnimatedMemoryAction
      className={props.className}
      label={props.label}
      icon={
        props.muted ? (
          <Icon name="SoundOff" size={props.iconSize} />
        ) : (
          <Icon name="SoundOn" size={props.iconSize} />
        )
      }
      onClick={props.onClick}
      variant="dark"
    />
  )
}
