import { apiFetcher } from 'api/goodtrust/api'
import { postUserFutureMessage } from 'api/goodtrust/user/futureMessage'
import { handleAndToastError } from 'components/Toast'
import { encodeQuery, FetchCall, QueryParams } from 'utils/fetcher'
import { ApiType } from 'utils/types'
import {
  clearLocalValue,
  getLocalUrl,
  LOCAL_FM_MESSAGE_UUID,
  LOCAL_FM_USER_UUID,
  setLocalValue,
  useLocalValue,
} from 'utils/localStorage'
import { TFutureType } from 'utils/analytics/types'
import { mutateAnalyticsData } from 'utils/analytics/mutate'
import { fireEvent } from 'utils/analytics/fire'
import Router from 'next/router'
import { mutate } from 'swr'
import { useCallback } from 'react'
import { TFunction } from 'react-i18next'
import { getTranslation } from 'utils/i18n/global'

export interface CreateFutureMessageWithRedirectParams {
  fetcher?: FetchCall | null
  redirectParams?: QueryParams
  type: TFutureType
  templateType?: FutureMessageTemplateType
}

export async function createFutureMessageWithRedirect(
  props: CreateFutureMessageWithRedirectParams
) {
  const { t } = getTranslation('common')
  const template = GET_FUTURE_MESSAGE_TEMPLATES(t)[props.templateType || 'NONE']

  try {
    const res = await postUserFutureMessage(
      {
        template_type: template.type,
        prefilled_body:
          template.type === 'NONE' ? undefined : `${template.body}${template.imageFigure}`,
        prefilled_title: template.type === 'NONE' ? undefined : template.title,
        file_uuids: template.imageUuid ? [template.imageUuid] : undefined,
      },
      props.fetcher
    )
    if (!res?.ok) {
      if (res.status === 403 && res.errorCode === 'user_exceeded_free_quota') {
        mutateAnalyticsData({ flow: 'future_message' })
        fireEvent('fm_paywall_hit', { create: 'yes' })
        await Router.push(
          encodeQuery('/subscription/future-messages', {
            next: '/me/life-stories?tab=future-messages',
          })
        )
        return
      }
      throw Error(res?.error?.toString?.())
    }
    fireEvent('fm_started', { type: props.type })

    if (props.fetcher === apiFetcher && res?.json?.uuid && res?.json.user_uuid) {
      await setFutureMessageStorage(res?.json.uuid, res?.json.user_uuid)
    }

    await Router.push(encodeQuery(`/me/future-message/${res?.json?.uuid}`, props.redirectParams))
  } catch (err) {
    handleAndToastError(err, 'failed_to_create_future_message')
  }
}

export async function setFutureMessageStorage(messageUuid: string, userUuid: string) {
  setLocalValue(LOCAL_FM_MESSAGE_UUID, messageUuid)
  setLocalValue(LOCAL_FM_USER_UUID, userUuid)
  await mutate(getLocalUrl(LOCAL_FM_MESSAGE_UUID))
  await mutate(getLocalUrl(LOCAL_FM_USER_UUID))
}

export const clearFutureMessageStorage = () => {
  clearLocalValue(LOCAL_FM_MESSAGE_UUID)
  clearLocalValue(LOCAL_FM_USER_UUID)
}

export const useFutureMessageStorage = () => {
  const {
    data: messageUuid,
    isValidating: isValidatingMessage,
    mutate: mutateMessage,
  } = useLocalValue(LOCAL_FM_MESSAGE_UUID)
  const {
    data: userUuid,
    isValidating: isValidatingUser,
    mutate: mutateUser,
  } = useLocalValue(LOCAL_FM_USER_UUID)
  const mutate = useCallback(async () => {
    await mutateMessage()
    await mutateUser()
  }, [mutateMessage, mutateUser])
  return {
    messageUuid,
    userUuid,
    isValidating:
      isValidatingMessage ||
      isValidatingUser ||
      messageUuid === undefined ||
      userUuid === undefined,
    mutate,
  }
}

export type FutureMessageTemplateType = ApiType['CreateFutureMessageCommand']['template_type']

export interface FutureMessageTemplate {
  type: FutureMessageTemplateType
  modalImage?: string
  imageUuid?: string
  imageFigure?: string
  background_url?: string
  body?: string
  name: string
  title?: string
}

const AWS_S3_BASE_URL = process.env.NEXT_PUBLIC_AWS_S3_BASE_URL

const prepareImgFigure = (imageUuid: string) => {
  return `<figure class="image"><img src="${AWS_S3_BASE_URL}/uploads/${imageUuid}.jpg"/></figure>\n<p>&nbsp;</p>`
}

const fmTemplateImageUuids: Record<Exclude<FutureMessageTemplateType, 'NONE'>, string> = {
  CELEBRATION: '2d56e85f-5775-4788-9ff1-d147714576ac',
  LAST_GOODBYE: '3cbeaa0f-3e53-43b4-b4ea-cbbcfb9281b3',
  FUTURE_ME: 'a45840bb-5e02-4f1d-b8f3-6f87d87ac195',
}

export const GET_FUTURE_MESSAGE_TEMPLATES: (
  t: TFunction<'common'>
) => Record<FutureMessageTemplateType, FutureMessageTemplate> = (t) => {
  return {
    NONE: { type: 'NONE', name: t('common.future_message_template.blank_name') },
    CELEBRATION: {
      type: 'CELEBRATION',
      name: t('common.future_message_template.celebration.name'),
      title: t('common.future_message_template.celebration.title'),
      background_url: '/images/future-message/bg_celebration.jpg',
      imageUuid: fmTemplateImageUuids.CELEBRATION,
      imageFigure: prepareImgFigure(fmTemplateImageUuids.CELEBRATION),
      modalImage: '/images/future-message/img_celebration.jpg',
      body: t('common.future_message_template.celebration.body'),
    },
    LAST_GOODBYE: {
      type: 'LAST_GOODBYE',
      name: t('common.future_message_template.last_goodbye.name'),
      title: t('common.future_message_template.last_goodbye.title'),
      background_url: '/images/future-message/bg_goodbye.jpg',
      imageUuid: fmTemplateImageUuids.LAST_GOODBYE,
      imageFigure: prepareImgFigure(fmTemplateImageUuids.LAST_GOODBYE),
      modalImage: '/images/future-message/img_goodbye.jpg',
      body: t('common.future_message_template.last_goodbye.body'),
    },
    FUTURE_ME: {
      type: 'FUTURE_ME',
      name: t('common.future_message_template.future_me.name'),
      title: t('common.future_message_template.future_me.title'),
      background_url: '/images/future-message/bg_future_me.jpg',
      imageUuid: fmTemplateImageUuids.FUTURE_ME,
      imageFigure: prepareImgFigure(fmTemplateImageUuids.FUTURE_ME),
      modalImage: '/images/future-message/img_future_me.jpg',
      body: t('common.future_message_template.future_me.body'),
    },
  }
}
