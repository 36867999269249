import { css } from '@emotion/react'
import { AspectRatio } from 'components/AspectRatio'
import { BlockSpinner } from 'components/spinner/Spinner'

export function VideoPlayerPlaceholder(props: { className?: string; ratio?: number }) {
  return (
    <AspectRatio
      ratio={props.ratio ?? 16 / 9}
      className={props.className}
      css={css`
        background: var(--c-blue100);
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <BlockSpinner />
    </AspectRatio>
  )
}
