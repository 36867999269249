import { InterpolationPrimitive } from '@emotion/serialize'
import {
  Children,
  cloneElement,
  forwardRef,
  HTMLProps,
  isValidElement,
  ReactNode,
  useEffect,
  useRef,
} from 'react'
import { logWarn, mergeRefs } from 'utils/general'
import { useImmerState } from 'utils/hooks'
import { SPlaceholderWrapper, SSpinner, SVideo, SVideoWrapper } from './AutoPlayer.styled'

interface AutoPlayerCustomProps {
  children?: ReactNode
  containerCss?: InterpolationPrimitive
  playerCss?: InterpolationPrimitive
  customPlaceholder?: ReactNode
  isAuth?: boolean
  noPlaceholderAbsolutePosition?: boolean
}

type AutoPlayerState = {
  isPlaying: boolean
  hasLoaded: boolean
}

function videoElementProxy(
  video: HTMLVideoElement | null
): Pick<HTMLVideoElement, 'play' | 'load' | 'pause'> {
  return {
    play: async () => {
      try {
        await video?.play()
      } catch (e) {
        console.error(e)
      }
    },
    pause: () => {
      try {
        video?.pause()
      } catch (e) {
        console.error(e)
      }
    },
    load: () => {
      try {
        video?.load()
      } catch (e) {
        console.error(e)
      }
    },
  }
}

export type AutoPlayerProps = Omit<
  HTMLProps<HTMLVideoElement> & AutoPlayerCustomProps,
  'className' | 'as'
>

export const AutoPlayer = forwardRef<HTMLVideoElement, AutoPlayerProps>(
  (
    {
      containerCss,
      playerCss,
      children,
      customPlaceholder,
      src,
      muted,
      noPlaceholderAbsolutePosition,
      ...props
    },
    ref
  ) => {
    const [playerState, setPlayerState] = useImmerState<AutoPlayerState>({
      isPlaying: false,
      hasLoaded: false,
    })
    const { isPlaying, hasLoaded } = playerState
    const videoElementRef = useRef<HTMLVideoElement>(null)

    useEffect(() => {
      if (!src) {
        return
      }
      videoElementProxy(videoElementRef.current).load()
      setPlayerState((state) => {
        state.hasLoaded = true
        state.isPlaying = false
      })
    }, [src, setPlayerState])

    return (
      <SVideoWrapper css={containerCss}>
        {!isPlaying && (
          <SPlaceholderWrapper css={noPlaceholderAbsolutePosition && { position: 'relative' }}>
            {customPlaceholder || <SSpinner />}
          </SPlaceholderWrapper>
        )}
        <SVideo
          loaded={hasLoaded}
          {...props}
          css={playerCss}
          src={src}
          ref={mergeRefs([ref, videoElementRef])}
          preload="metadata"
          playsInline
          autoPlay
          muted={hasLoaded && muted != null ? muted : true}
          onPlaying={() => {
            setPlayerState((state) => {
              state.isPlaying = true
            })
          }}
          onClick={async () => {
            try {
              if (hasLoaded && !isPlaying) {
                await videoElementProxy(videoElementRef.current).play()
              }
            } catch (e) {
              logWarn('AutoPlayer failed on user click: ', e)
            }
          }}
          onEnded={(e) => {
            props.onEnded?.(e)
            videoElementProxy(videoElementRef.current).play()
          }}
        />
        {Children.map(children, (item) => {
          if (isValidElement<{ style: any }>(item)) {
            return cloneElement(item, {
              style: {
                ...item.props.style,
                zIndex: 2,
              },
            })
          }
          return item
        })}
        {children}
      </SVideoWrapper>
    )
  }
)
AutoPlayer.displayName = 'AutoPlayer'
