import styled from '@emotion/styled'
import { BREAKPOINTS } from 'utils/styled'
import { Text } from 'components/Typography'

export const SOuterContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${BREAKPOINTS.MD.max} {
    margin-top: 1.5rem;
    flex-direction: column;
  }

  @media ${BREAKPOINTS.MD.min} {
    margin-top: 3rem;
  }
`

export const SLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  position: relative;

  @media ${BREAKPOINTS.MD.max} {
    width: 100%;
    order: 1;
  }
`

export const SRightContainer = styled.div`
  width: 47%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${BREAKPOINTS.MD.max} {
    order: 2;
    width: 100%;
  }
`

export const SContentBox = styled.div`
  width: 100%;
  position: relative;
`

export const SDescription = styled(Text)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @media ${BREAKPOINTS.MD.min} {
    max-width: 25rem;
  }
`
export const STextHeader = styled(Text)`
  font-weight: 800;
  font-size: 1.25rem;
  line-height: 150%;
  align-self: flex-start;
`
