import useSWR from 'swr'
import { logError } from 'utils/general'

export const LOCAL_FM_MESSAGE_UUID = 'future_message_message_uuid'
export const LOCAL_FM_USER_UUID = 'future_message_user_uuid'

export function setLocalValueForTime(key: string, value: string, time: number) {
  setLocalValue(key, value)
  setTimeout(() => {
    clearLocalValue(key)
  }, time)
}

export function setLocalValue(key: string, value: string) {
  if (typeof Storage !== 'undefined') {
    localStorage.setItem(key, value)
  } else {
    logError('Local storage is not defined for writing!')
  }
}

export function clearLocalValue(key: string) {
  if (typeof Storage !== 'undefined') {
    return localStorage.removeItem(key)
  } else {
    logError('LocalStorage is not defined for clearing!')
    return null
  }
}

function createLocalFetcher(key: string) {
  return () => {
    const value = localStorage.getItem(key)
    return value ?? null
  }
}

export function useLocalValue(key: string) {
  return useSWR(`@local-storage-${key}`, createLocalFetcher(key), {
    revalidateOnMount: true,
    fallbackData: undefined,
  })
}

export function getLocalUrl(key: string) {
  return `@local-storage-${key}`
}
