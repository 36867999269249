import { css } from '@emotion/react'
import { SingingPortraitFragment } from 'api/contentful/contentful.graphql.types'
import { ContentfulMediaMentions } from 'api/contentful/getContentfulMediaMention'
import { Block } from 'components/Block'
import { StoryPortraitButtons } from 'components/content/storyPortraits/StoryPortraitButtons'
import {
  SContentBox,
  SDescription,
  SLeftContainer,
  SOuterContainer,
  SRightContainer,
  STextHeader,
} from './StoryPortraitsHero.styled'
import { HorizontalScroll } from 'components/horizontalScroll/HorizontalScroll'
import { LandingPageTextImageMediaMentions } from 'components/landingPageTextImageSection/LandingPageTextImageMediaMentions'
import { Only } from 'components/only/Only'
import { Spacer } from 'components/spacer/Spacer'
import { useStoryPortraitLogic } from 'components/storyPortraits/logic'
import { ToggleAudioButton } from 'components/toggleAudioButton/ToggleAudioButton'
import { Title, Title_h2_default } from 'components/Typography'
import { VideoCarousel } from 'components/videoCarousel/VideoCarousel'
import { AutoPlayer } from 'components/videoPlayer/autoPlayer/AutoPlayer'
import { useTranslation } from 'next-i18next'
import React, { FC, useState } from 'react'
import { colors } from 'utils/colors'
import { pxToRemUnit } from 'utils/styled'

export function StoryPortraitsHero(props: {
  className?: string
  previewAnimations?: SingingPortraitFragment[]
  mobileCarouselTitle: string
  mediaMentions: ContentfulMediaMentions
  mediaMentionsTitle?: string
}) {
  const logic = useStoryPortraitLogic()
  const { t } = useTranslation('memory')

  return (
    <Block className={props.className}>
      <SOuterContainer>
        <SLeftContainer>
          <SContentBox>
            <Title variant={Title_h2_default}>
              <span className="mobile-only">{t('memory.story.title_mobile')}</span>
              <span className="desktop-only">{t('memory.story.title_desktop')}</span>
            </Title>
            <SDescription>
              <span className="mobile-only">{t('memory.story.desc_mobile')}</span>
              <span className="desktop-only">{t('memory.story.desc_desktop')}</span>
            </SDescription>
            <StoryPortraitButtons logic={logic} />
            <Spacer size={[0, 1.5]} />
            <LandingPageTextImageMediaMentions
              className="desktop-only"
              title={props.mediaMentionsTitle}
              mentions={props.mediaMentions}
              responsiveItemCounts={[7, 6, 7]}
            />
          </SContentBox>
        </SLeftContainer>

        <SRightContainer>
          <Only on="mobile" css={{ width: '100%' }}>
            <Spacer size={1} />
            <STextHeader color={colors.gray[100]}>{props.mobileCarouselTitle}</STextHeader>
            <Spacer size={1} />
          </Only>

          <VideoCarousel
            items={props.previewAnimations}
            className="desktop-only"
            hideDots
            hideActionText
            iconSize="1rem"
            iconPlacement="right"
            css={{
              video: { objectFit: 'cover', borderRadius: '1rem' },
            }}
          />
          <HorizontalScroll
            className="mobile-only"
            leftOffsetStr="1rem"
            scrollerCss={css`
              padding: 0 1rem;
              grid-gap: 0.875rem;
            `}
          >
            {props.previewAnimations?.map((content) => (
              <MobileVideo key={content.sys.id} content={content} />
            ))}
          </HorizontalScroll>
        </SRightContainer>
      </SOuterContainer>
    </Block>
  )
}

const MobileVideo: FC<React.PropsWithChildren<{ content: SingingPortraitFragment }>> = (props) => {
  const [muted, setMuted] = useState(true)
  return (
    <div
      css={{
        position: 'relative',
        width: pxToRemUnit(280),

        overflow: 'hidden',
        video: {
          height: pxToRemUnit(210),
          borderRadius: '0.75rem',
          objectFit: 'cover',
        },
      }}
    >
      <AutoPlayer src={String(props.content.video?.url)} muted={muted} />
      <div css={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}>
        <ToggleAudioButton onClick={() => setMuted((m) => !m)} muted={muted} iconSize="1.5rem" />
      </div>
    </div>
  )
}
