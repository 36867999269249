import { forwardRef, PropsWithChildren, useEffect, useRef } from 'react'
import {
  SHorizontalScroll,
  SHorizontalScrollContent,
  SHorizontalScrollOverlayLeft,
  SHorizontalScrollOverlayRight,
} from './HorizontalScroll.styled'
import { mergeRefs } from 'utils/general'
import { InterpolationPrimitive } from '@emotion/serialize'
import { useWindowSize } from 'utils/hooks'

export type HorizontalScrollProps = {
  className?: string
  scrollerCss?: InterpolationPrimitive
  leftOffset?: number
  leftOffsetStr?: string
  initialScroll?: number
}

export const HorizontalScroll = forwardRef<
  HTMLDivElement,
  PropsWithChildren<HorizontalScrollProps>
>((props, ref) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const isScrollingRef = useRef(false)

  const { isXXXLarge } = useWindowSize()

  useEffect(() => {
    if (props.initialScroll && scrollRef.current) {
      scrollRef.current.scrollLeft = props.initialScroll
    }
  }, [props.initialScroll])

  useEffect(() => {
    if (scrollRef.current) {
      let isDown = false
      let startX = 0
      let scrollLeft = 0

      scrollRef.current.addEventListener('mousedown', (e) => {
        isScrollingRef.current = false
        setTimeout(() => (isScrollingRef.current = true), 200)
        isDown = true
        if (scrollRef.current) {
          scrollRef.current.classList.add('active')
          startX = e.pageX - scrollRef.current.offsetLeft
          scrollLeft = scrollRef.current.scrollLeft
        }
      })
      scrollRef.current.addEventListener('mouseleave', () => {
        isDown = false
        if (scrollRef.current) {
          scrollRef.current.classList.remove('active')
        }
      })
      scrollRef.current.addEventListener('mouseup', () => {
        isDown = false
        setTimeout(() => (isScrollingRef.current = false), 10)
        if (scrollRef.current) {
          scrollRef.current.classList.remove('active')
        }
      })
      scrollRef.current.addEventListener('mousemove', (e) => {
        if (!isDown) return
        e.preventDefault()
        if (scrollRef.current) {
          const x = e.pageX - scrollRef.current.offsetLeft
          const walk = (x - startX) * 3 //scroll-fast
          scrollRef.current.scrollLeft = scrollLeft - walk
        }
      })
    }
  }, [scrollRef])

  return (
    <SHorizontalScroll
      className={props.className}
      leftOffsetStr={props.leftOffsetStr}
      leftOffset={props.leftOffset}
    >
      {isXXXLarge && <SHorizontalScrollOverlayLeft />}
      <SHorizontalScrollContent
        ref={mergeRefs([scrollRef, ref])}
        css={props.scrollerCss}
        onClickCapture={(e) => {
          if (isScrollingRef.current) e.stopPropagation()
        }}
      >
        {props.children}
      </SHorizontalScrollContent>
      {isXXXLarge && <SHorizontalScrollOverlayRight />}
    </SHorizontalScroll>
  )
})
HorizontalScroll.displayName = 'HorizontalScroll'
