import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { BlockSpinner } from 'components/spinner/Spinner'
import { autoPlayerVideoZIndex } from './constants'

export const SVideoWrapper = styled.div`
  position: relative;
`

export const SVideo = styled.video<{ loaded: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  ${({ loaded }) =>
    css`
      display: ${loaded ? 'block' : 'none'};
    `}
  z-index: ${autoPlayerVideoZIndex};
`

export const SPlaceholderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SSpinner = styled(BlockSpinner)`
  color: var(--c-blue500);
  transform: translateX(-50%);
`
