import styled from '@emotion/styled'
import { Caption } from './Typography'

export const Tag = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: var(--c-white);
  background: var(--c-gray100);
  padding: 6px 17px;

  font-family: var(--f-text);
  font-size: 16px;
  line-height: 1.62;
  font-weight: bold;
  border-radius: 2px;
  vertical-align: middle;
`

export const LightTag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--f-text);
  padding: 4px 0 5px;
  border-radius: 4px;
  border: 2px solid var(--c-blue100);
  text-align: center;
  color: var(--c-gray500);
`

export const OrangeTag = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: var(--c-white);
  background: var(--c-orange200);
  border-radius: 30px;
  vertical-align: middle;
  padding: 0 0.75rem;

  font-family: var(--f-text);
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 900;
  letter-spacing: 0.08em;
`

export const LightBlueTag = styled(Caption)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: var(--c-blue500);
  background: var(--c-blue100);
  border-radius: 30px;
  vertical-align: middle;
  padding: 0 0.75rem;
`
