import { contentful } from 'api/contentful/api'
import {
  GetStoryPortraitCarouselQuery,
  SingingPortraitFragment,
} from 'api/contentful/contentful.graphql.types'
import { getContentfulFAQ } from 'api/contentful/getContentfulFAQ'
import {
  ContentfulMediaMentions,
  getContentfulMediaMentions,
} from 'api/contentful/getContentfulMediaMention'
import { Block } from 'components/Block'
import { LifeStoriesContextProvider } from 'components/content/lifeStories/LifeStoriesContext'
import { StoryPortraitsHero } from 'components/content/storyPortraits/StoryPortraitsHero'
import { Footer } from 'components/footer/Footer'
import { TopNavigationLandingPage } from 'components/header/topNavigationLandingPage/TopNavigationLandingPage'
import { Layout } from 'components/layout/Layout'
import { Spacer } from 'components/spacer/Spacer'
import { StoryPortraitsPageContent } from 'components/storyPortraits/pages/StoryPortaitsPageContent'
import { StoryPortraitsMeta } from 'components/storyPortraits/StoryPortraitsMeta'
import { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { colors } from 'utils/colors'
import { REVALIDATE_SECS } from 'utils/constants'
import { getContentfulImages } from 'utils/contentful/getPageImages'
import { isNonNullable } from 'utils/general'
import { getIntlProps } from 'utils/i18n/getIntlProps'

type StoryPortraitsPageContentProps = React.ComponentProps<typeof StoryPortraitsPageContent>

type InitialProps = StoryPortraitsPageContentProps & {
  carousel: GetStoryPortraitCarouselQuery
  mediaMentions: ContentfulMediaMentions
}

export default function StoryPortraitsPage(props: InitialProps) {
  const { t } = useTranslation('memory')
  return (
    <LifeStoriesContextProvider>
      <Layout
        header={<TopNavigationLandingPage variant="full" noSpacingBelow />}
        footer={<Footer variant="full" />}
      >
        <StoryPortraitsMeta />

        <StoryPortraitsHero
          previewAnimations={props.carousel.memorySetupCollection?.items[0]?.storyPortraitCarouselCollection?.items
            .filter(isNonNullable)
            .map((item): SingingPortraitFragment => {
              return {
                sys: {
                  id: item.sys.id,
                },
                video: {
                  url: item.url,
                },
              }
            })}
          mobileCarouselTitle={t('memory.story.carousel_title')}
          mediaMentions={props.mediaMentions}
          mediaMentionsTitle={t('memory.story.mentions_caption')}
        />

        <Spacer size={[1.25, 3]} />

        <Block>
          <div css={{ height: 1, width: '100%', backgroundColor: colors.blue[100] }}></div>
        </Block>

        <Spacer size={[1.5, 2]} />

        <StoryPortraitsPageContent faq={props.faq} categories={props.categories} />
      </Layout>
    </LifeStoriesContextProvider>
  )
}

export const getStaticProps: GetStaticProps<InitialProps> = async (ctx) => {
  const carousel = await contentful.getStoryPortraitCarousel()
  const mediaMentions = await getContentfulMediaMentions('global')
  const faq = await getContentfulFAQ('story-portraits')

  const categories = await contentful.getStoryPortraitCategories()

  const props = {
    ...(await getContentfulImages()),
    ...(await getIntlProps(ctx, ['memory', 'home', 'pricing_plan'])),
    carousel,
    mediaMentions,
    faq,
    categories,
  }

  return { props, revalidate: REVALIDATE_SECS }
}
