import styled from '@emotion/styled'

export const SVideoWithPreview = styled.div`
  overflow: visible;
  width: 100%;
  position: relative;
  & video {
    border-radius: 2px;
  }
  & img {
    max-width: 40%;
    max-height: 40%;
    object-fit: contain;
    z-index: 2;
    position: absolute;
    bottom: -5%;
    left: -5%;
    border-radius: 2px;
  }
  & div {
    width: 100%;
  }
`

export const SActionsWrapper = styled.div`
  position: absolute;
  top: -0.7rem;
  left: -0.7rem;
  display: flex;
  flex-direction: column;
  width: unset !important;
`
